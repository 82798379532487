exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[id]-js": () => import("./../../../src/pages/[id].js" /* webpackChunkName: "component---src-pages-[id]-js" */),
  "component---src-pages-aktualnosci-[id]-js": () => import("./../../../src/pages/aktualnosci/[id].js" /* webpackChunkName: "component---src-pages-aktualnosci-[id]-js" */),
  "component---src-pages-aktualnosci-index-js": () => import("./../../../src/pages/aktualnosci/index.js" /* webpackChunkName: "component---src-pages-aktualnosci-index-js" */),
  "component---src-pages-bilety-js": () => import("./../../../src/pages/bilety.js" /* webpackChunkName: "component---src-pages-bilety-js" */),
  "component---src-pages-biznes-js": () => import("./../../../src/pages/biznes.js" /* webpackChunkName: "component---src-pages-biznes-js" */),
  "component---src-pages-fotogalerie-[id]-js": () => import("./../../../src/pages/fotogalerie/[id].js" /* webpackChunkName: "component---src-pages-fotogalerie-[id]-js" */),
  "component---src-pages-fotogalerie-index-js": () => import("./../../../src/pages/fotogalerie/index.js" /* webpackChunkName: "component---src-pages-fotogalerie-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jagatv-[id]-js": () => import("./../../../src/pages/jagatv/[id].js" /* webpackChunkName: "component---src-pages-jagatv-[id]-js" */),
  "component---src-pages-jagatv-index-js": () => import("./../../../src/pages/jagatv/index.js" /* webpackChunkName: "component---src-pages-jagatv-index-js" */),
  "component---src-pages-karnety-js": () => import("./../../../src/pages/karnety.js" /* webpackChunkName: "component---src-pages-karnety-js" */),
  "component---src-pages-klient-js": () => import("./../../../src/pages/klient/[...].js" /* webpackChunkName: "component---src-pages-klient-js" */),
  "component---src-pages-matchroom-[id]-js": () => import("./../../../src/pages/matchroom/[id].js" /* webpackChunkName: "component---src-pages-matchroom-[id]-js" */),
  "component---src-pages-rozgrywki-[id]-js": () => import("./../../../src/pages/rozgrywki/[id].js" /* webpackChunkName: "component---src-pages-rozgrywki-[id]-js" */),
  "component---src-pages-rozgrywki-index-js": () => import("./../../../src/pages/rozgrywki/index.js" /* webpackChunkName: "component---src-pages-rozgrywki-index-js" */),
  "component---src-pages-szatnia-[id]-js": () => import("./../../../src/pages/szatnia/[id].js" /* webpackChunkName: "component---src-pages-szatnia-[id]-js" */),
  "component---src-pages-szatnia-index-js": () => import("./../../../src/pages/szatnia/index.js" /* webpackChunkName: "component---src-pages-szatnia-index-js" */),
  "component---src-pages-szukaj-index-js": () => import("./../../../src/pages/szukaj/index.js" /* webpackChunkName: "component---src-pages-szukaj-index-js" */),
  "component---src-pages-zmiana-hasla-[id]-js": () => import("./../../../src/pages/zmiana-hasla/[id].js" /* webpackChunkName: "component---src-pages-zmiana-hasla-[id]-js" */)
}

